import React from 'react';
import styled from 'styled-components';
import 'styled-components/macro';
import { useStatBlockContext } from '../../../../context/StatblockContext';

const BasicAttributesStyles = styled.div`
	display: flex;
	justify-content: center;
`;

const BasicAttributesSingle = ({
	name,
	value,
}: {
	name: string;
	value: string;
}) => (
	<div
		css={`
			padding: 1rem;
			font-size: 0.9rem;
			text-align: center;
			color: #822000;
		`}
	>
		<div
			css={`
				font-weight: 600;
			`}
		>
			{name}
		</div>
		<div>{value}</div>
	</div>
);

const BasicAttributes = () => {
	const { character } = useStatBlockContext();

	if (character === null) {
		return null;
	}

	return (
		<BasicAttributesStyles>
			<BasicAttributesSingle
				name="STR"
				value={character?.basicAttributes.STR}
			/>
			<BasicAttributesSingle
				name="GES"
				value={character?.basicAttributes.DEX}
			/>
			<BasicAttributesSingle
				name="KON"
				value={character?.basicAttributes.CON}
			/>
			<BasicAttributesSingle
				name="INT"
				value={character?.basicAttributes.INT}
			/>
			<BasicAttributesSingle
				name="WEI"
				value={character?.basicAttributes.WIS}
			/>
			<BasicAttributesSingle
				name="CHA"
				value={character?.basicAttributes.CHA}
			/>
		</BasicAttributesStyles>
	);
};

export default BasicAttributes;
