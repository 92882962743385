import { createContext, useContext } from 'react';
import { Character } from '../@types/character.types';

export type StatblockContextType = {
	character: Character | null;
	setContext: Function;
};

const StatblockContext = createContext<StatblockContextType>({
	character: null,
	setContext: () => {},
});

export default StatblockContext;

export const useStatBlockContext = () => useContext(StatblockContext);
