import React from 'react';
import styled from 'styled-components';
import { useStatBlockContext } from '../../../../context/StatblockContext';
import Paragraph from '../paragraph/paragraph.component';

const BasicStatsStyles = styled.div``;

const BasicStats = () => {
	const { character } = useStatBlockContext();

	if (character === null) {
		return null;
	}

	return (
		<BasicStatsStyles>
			<Paragraph title="Rüstungsklasse">{character.armorClass}</Paragraph>
			<Paragraph title="Lebenspunkte">{character.hitpoints}</Paragraph>
			<Paragraph title="Bewegungsgeschwindigkeit">{character.speed}</Paragraph>
		</BasicStatsStyles>
	);
};

export default BasicStats;
