import React, { useEffect, useState } from 'react';
import type {} from 'styled-components/cssprop';
import 'styled-components/macro';
import { ApiResponse } from './@types/api-response.type';
import { Character } from './@types/character.types';
import Layout from './components/layout/layout.component';
import MonsterSelection from './components/monster-selection/monster-selection.component';
import Statblock from './components/statblock/statblock.component';
import StatblockContext, {
	StatblockContextType,
} from './context/StatblockContext';

export const testCharacter: Character = {
	pageId: 'testId',
	name: 'Glubtok',
	challengeRating: 5,
	hitpoints: 45,
	armorClass: 15,
	speed: '30m',
	type: 'Mittelgroßes Monster, rechtschaffen gut',
	basicAttributes: {
		STR: '10 (+0)',
		DEX: '10 (+0)',
		CON: '10 (+0)',
		INT: '10 (+0)',
		CHA: '10 (+0)',
		WIS: '10 (+0)',
	},
};

function App() {
	const [statBlockContextValue, setStatBlockContextValue] =
		useState<StatblockContextType>({
			character: testCharacter,
			setContext: () => {},
		});
	const [monsterEntities, setMonsterEntities] = useState<null | Character[]>(
		null
	);

	useEffect(() => {
		setStatBlockContextValue({
			character: testCharacter,
			setContext: setStatBlockContextValue,
		});
	}, []);

	useEffect(() => {
		fetch('/api/get-monster-entries')
			.then((result) => result.json())
			.then((result: ApiResponse) => {
				if ('error' in result) {
					return;
				}
				setMonsterEntities(result);
			});
	}, []);

	return (
		<div className="App">
			<Layout>
				<StatblockContext.Provider value={statBlockContextValue}>
					<MonsterSelection monster={monsterEntities} />
					<Statblock />
				</StatblockContext.Provider>
			</Layout>
		</div>
	);
}

export default App;
