import React from 'react';

const Paragraph = ({
	title,
	children,
}: {
	title: string;
	children: React.ReactNode;
}) => {
	return (
		<div
			style={{
				color: '#822000',
				paddingBottom: '1px',
				paddingTop: '1px',
			}}
		>
			<span style={{ fontWeight: 600 }}>{title}</span>
			<span style={{ paddingLeft: '5px' }}>{children}</span>
		</div>
	);
};

export default Paragraph;
