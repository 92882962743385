import React from 'react';
import styled from 'styled-components';

const StyledWrapper = styled.div`
	background-image: url('/image/background_texture.avif');
	background-size: cover;
	min-width: 600px;
	max-width: 850px;
	box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
`;

const WrapperBorder = styled.div`
	width: 100%;
	height: 6px;
	background-image: url('/image/border_texture.avif');
	background-size: 100% 100%;
`;

const Inner = styled.div`
	padding: 0 15px;
`;

const Wrapper = ({ children }: { children: React.ReactNode }) => {
	return (
		<StyledWrapper>
			<WrapperBorder />
			<Inner>{children}</Inner>
			<WrapperBorder />
		</StyledWrapper>
	);
};

export default Wrapper;
