import React from 'react';
import { useStatBlockContext } from '../../context/StatblockContext';
import Alignment from './components/alignment/alignment.component';
import BasicAttributes from './components/basic-attributes/basic-attributes.component';
import BasicStats from './components/basic-stats/basic-stats.component';
import Divider from './components/divider/divider.component';
import TextContent from './components/text-content/text-content.component';
import Title from './components/title/title.component';
import Wrapper from './components/wrapper/wrapper.component';

const Statblock = () => {
	const context = useStatBlockContext();

	if (context === null || context.character === null) {
		return null;
	}

	const { character } = context;

	return (
		<Wrapper>
			<Title>{character.name}</Title>
			<Alignment>{character.type}</Alignment>
			<Divider />
			<BasicStats />
			<Divider />
			<BasicAttributes />
			<Divider />
			<TextContent />
		</Wrapper>
	);
};

export default Statblock;
