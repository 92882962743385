import React from 'react';
import BlockTypes from './blocktypes.enum';

type ContentBlocks = Array<{
	type: BlockTypes;
	content: any;
	children: null | ContentBlocks;
}>;

type RichTextObject = {
	type: string;
	text: {
		content: string;
		link: null | string;
	};
	annotations: {
		bold: boolean;
		italic: boolean;
		strikethrough: boolean;
		underline: boolean;
		code: boolean;
		color: string;
	};
	plain_text: string;
	href: string | null;
};

const TransformRichtext = ({
	richTextObject,
}: {
	richTextObject: RichTextObject;
}) => {
	const style = { fontWeight: 500, fontStyle: 'inherit' };

	if (richTextObject.annotations.bold) {
		style.fontWeight = 600;
	}

	if (richTextObject.annotations.italic) {
		style.fontStyle = 'italic';
	}

	return <span style={style}>{richTextObject.text.content}</span>;
};

const TransformStatblockContent = ({
	contentBlocks,
}: {
	contentBlocks: ContentBlocks;
}) => {
	const renderedBlocks = contentBlocks.map((block) => {
		switch (block.type) {
			case BlockTypes.PARAGRAPH:
				return (
					<p key={block.content.id}>
						{block.content.paragraph.rich_text.map(
							(richTextObject: RichTextObject) => (
								<TransformRichtext richTextObject={richTextObject} />
							)
						)}
						{block.content.has_children && block.children && (
							<TransformStatblockContent contentBlocks={block.children} />
						)}
					</p>
				);
			case BlockTypes.BULLETED_LIST_ITEM:
				return (
					<li key={block.content.id}>
						{block.content.bulleted_list_item.rich_text.map(
							(richTextObject: RichTextObject) => (
								<TransformRichtext richTextObject={richTextObject} />
							)
						)}
						{block.content.has_children && block.children && (
							<TransformStatblockContent contentBlocks={block.children} />
						)}
					</li>
				);
			case BlockTypes.HEADING2:
				return (
					<h2 key={block.content.id}>
						{block.content.heading_2.rich_text[0].plain_text}
					</h2>
				);
			default:
				return null;
		}
	});

	return <div>{renderedBlocks.map((block) => block)}</div>;
};

export default TransformStatblockContent;
