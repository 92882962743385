import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useStatBlockContext } from '../../../../context/StatblockContext';
import TransformStatblockContent from '../../../../services/transform-statblock-content/transform-statblock-content.service';

const TextContentStyles = styled.div`
	li {
		padding-bottom: 3px;
	}
	li li {
		padding-left: 16px;
	}
`;

const TextContent = () => {
	const context = useStatBlockContext();
	const [textContextBlocks, setTextContentBlocks] = useState(null);

	useEffect(() => {
		if (context.character === null) {
			return;
		}
		fetch(`/api/get-monster-entry-content?pageId=${context.character.pageId}`)
			.then((result) => result.json())
			.then((apiResponse) => setTextContentBlocks(apiResponse));
	}, [context.character]);

	if (
		context === null ||
		context.character === null ||
		textContextBlocks === null ||
		'error' in textContextBlocks
	) {
		return null;
	}

	return (
		<TextContentStyles>
			<TransformStatblockContent contentBlocks={textContextBlocks} />
		</TextContentStyles>
	);
};

export default TextContent;
