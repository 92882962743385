import React from 'react';
import styled from 'styled-components';

const Title = ({
	className,
	children,
}: {
	className?: string;
	children: string;
}) => <div className={className}>{children}</div>;

export default styled(Title)`
	font-family: 'MrsEavesSmallCaps';
	color: #822000;
	font-size: 2rem;
	font-weight: 600;
	padding: 1.5rem 0 0.1rem 0;
`;
