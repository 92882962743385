import React from 'react';
import styled from 'styled-components';

const DividerImage = styled.div`
	height: 5px;
	width: 100%;
	background-image: url('/image/divider.svg');
	background-repeat: no-repeat;
	background-size: 100% auto;
`;

const DividerWrapper = styled.div`
	padding: 10px 0;
`;

const Divider = () => {
	return (
		<DividerWrapper>
			<DividerImage />
		</DividerWrapper>
	);
};

export default Divider;
