import React from 'react';
import styled from 'styled-components';

const LayoutWrapper = styled.div`
	display: flex;
	align-items: center;
	flex-flow: column;
	padding: 3rem 0;
`;

const Layout = ({ children }: { children: React.ReactNode }) => {
	return <LayoutWrapper>{children}</LayoutWrapper>;
};

export default Layout;
