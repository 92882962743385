import React from 'react';
import { useStatBlockContext } from '../../context/StatblockContext';
import { Character } from '../../@types/character.types';

const MonsterSelection = ({ monster }: { monster: null | Character[] }) => {
	const { character, setContext } = useStatBlockContext();

	if (monster === null || character === null) {
		return null;
	}

	const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		const currentEntity = monster.filter(
			(entity) => entity.pageId === event.target.value
		)[0];

		if (currentEntity === undefined) {
			return;
		}

		setContext({ character: currentEntity, setContext: setContext });
	};

	return (
		<select onChange={handleChange} defaultValue={''}>
			{' '}
			<option value={''}>---</option>
			{monster.map((monster) => (
				<option key={monster.pageId} value={monster.pageId}>
					{monster.name}
				</option>
			))}{' '}
		</select>
	);
};

export default MonsterSelection;
